<template>
  <div class="home">
    <CommonHeader title="实名认证" />
    <div class="edit">
      <div class="edit-avatar item-flex-center"></div>
      <div class="edit-item flex pb32">
        <div class="l">证件照片</div>
        <div class="c">
          <div>
            <van-uploader :after-read="val => modeUpload(val, 'front')">
              <div
                v-if="idcard_just_photo"
                class="id-img"
              >
                <img
                  :src="idcard_just_photo"
                  alt=""
                >
              </div>
              <div
                v-else
                class="upload item-flex-center flex-direction"
              >
                <div class="img">
                  <img
                    src="https://artimg2.artart.cn/image/20220214/8795de73841913c63ce25c98a63056d5.png"
                    alt=""
                  >
                </div>
                <div class="txt">证件正面（人像面）</div>
              </div>
            </van-uploader>
          </div>
          <div>
            <van-uploader :after-read="val => modeUpload(val, 'back')">
              <div
                v-if="idcard_back_photo"
                class="id-img mt32"
              >
                <img
                  :src="idcard_back_photo"
                  alt=""
                >
              </div>
              <div
                v-else
                class="upload item-flex-center flex-direction mt32"
              >
                <div class="img">
                  <img
                    src="https://artimg2.artart.cn/image/20220214/8795de73841913c63ce25c98a63056d5.png"
                    alt=""
                  >
                </div>
                <div class="txt">证件反面</div>
              </div>
            </van-uploader>
          </div>
        </div>
      </div>
      <div class="edit-item flex align-items-c">
        <div class="l">真实姓名</div>
        <div class="c">
          <input
            type="text"
            :disabled="idcard_type == 1"
            v-model="real_name"
            :placeholder="idcard_type == 1 ? '上传证件照片自动识别' : '请输入'"
          >
        </div>
      </div>
      <div
        class="edit-item flex align-items-c"
        @click="showChangeTypeAction"
      >
        <div class="l">证件类型</div>
        <div class="c">
          <span v-if="idcard_type === 1">大陆身份证</span>
          <span v-else-if="idcard_type === 2">香港身份证</span>
          <span v-else-if="idcard_type === 3">澳门身份证</span>
          <span v-else-if="idcard_type === 4">台湾身份证</span>
        </div>
        <div class="r">
          <img
            src="https://artimg2.artart.cn/image/20220214/18fadab7518b23862eb44b75a055107c.png"
            alt=""
          >
        </div>
      </div>
      <div class="edit-item flex align-items-c">
        <div class="l">证件号码</div>
        <div class="c">
          <input
            type="text"
            :disabled="idcard_type == 1"
            v-model="idcard"
            :placeholder="idcard_type == 1 ? '上传证件照片自动识别' : '请输入'"
          >
        </div>
      </div>
    </div>
    <div
      class="prop item-flex-center"
      v-if="verify_status == 0 || verify_status == -1"
    >
      <van-checkbox v-model="checked">
        <div>已阅读并同意 <span @click="goPageAction('/users/agreement?type=3')">《用户注册协议》</span><span @click="goPageAction('/users/agreement?type=4')">《隐私政策》</span></div>
      </van-checkbox>
    </div>
    <div
      class="prop item-flex-center"
      v-else
    >
      <img
        src="https://artimg2.artart.cn/image/20220214/9546d8eaa28bf31887a6d789194c628d.png"
        alt=""
      >
      <div>个人信息加密保护中</div>
    </div>
    <div
      v-if="verify_status == 0 || verify_status == -1"
      class="save-btn"
      :style="rightBtnStyle"
      @click="saveAction"
    >提交认证</div>
    <div
      v-if="showChangeType"
      class="show-change-bg"
      @click="onCancel"
    ></div>
    <div
      v-if="showChangeType"
      class="show-change"
      @click.stop="kong"
    >
      <van-picker
        title="证件类型"
        :item-height="70"
        :columns="columns"
        :default-index="constoryIndex"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { applyVerify, applyVerifyInfo, checkUserIdCardImage } from "@/api/user";
import { Toast, Uploader, picker, checkbox } from "vant";
import { getOssUploadConfig } from "@/api/system";
import { uploadImg, isWeiXin } from "@/utils/util";
import OSS from "ali-oss";

export default {
  components: {
    [Uploader.name]: Uploader,
    [picker.name]: picker,
    [checkbox.name]: checkbox,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const real_name = ref("");
    const idcard = ref("");
    const idcard_just_photo = ref("");
    const idcard_back_photo = ref("");
    const idcard_type = ref(1);
    const checked = ref(false);

    const frontData = ref({});
    const backData = ref({});

    const columns = ref([
      "大陆身份证",
      "香港身份证",
      "澳门身份证",
      "台湾身份证",
    ]);

    const showChangeType = ref(false);
    const constoryIndex = ref(0);

    const verify_status = ref(0);

    const ossClient = ref(null);
    onBeforeMount(() => {
      applyVerifyInfo().then((res) => {
        if (
          res.data &&
          res.data.verify_status !== 0 &&
          res.data.verify_status !== -1
        ) {
          idcard_just_photo.value = res.data.idcard_just_photo;
          idcard.value = res.data.idcard;
          real_name.value = res.data.real_name;
          idcard_back_photo.value = res.data.idcard_back_photo;
          idcard_type.value = res.data.idcard_type;
        }
        verify_status.value = res.data.verify_status || 0;
        // console.log(res);
      });
      getOssUploadConfig({ type: 1 })
        .then((res) => {
          ossClient.value = new OSS({
            region: res.data.region,
            accessKeyId: res.data.Credentials.AccessKeyId,
            accessKeySecret: res.data.Credentials.AccessKeySecret,
            stsToken: res.data.Credentials.SecurityToken,
            bucket: res.data.bucket,
          });
        })
        .catch((message) => Toast(message));
    });
    // 左按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const kong = () => {};

    const showChangeTypeAction = () => {
      constoryIndex.value = idcard_type.value - 1;
      showChangeType.value = true;
    };

    const onConfirm = (value, index) => {
      idcard_just_photo.value = "";
      idcard_back_photo.value = "";
      idcard.value = "";
      real_name.value = "";
      idcard_type.value = index + 1;
      showChangeType.value = false;
    };

    const onCancel = () => {
      showChangeType.value = false;
    };

    const modeUpload = (file, key) => {
      const f = file.file;
      if (f.type.indexOf("image") === -1) {
        return;
      }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      uploadImg(f, ossClient, (result) => {
        if (result.data.status == 200) {
          if (idcard_type.value == 1) {
            checkUserIdCardImage({
              idCardImage: result.data.data.image_url.replace(/^https:\/\/cdn.weipai.shop/, "https://artimg2.artart.cn"),
              idCardSide: key,
            })
              .then((res) => {
                Toast.clear();
                if (key === "front") {
                  idcard_just_photo.value = result.data.data.image_url.replace(/^https:\/\/cdn.weipai.shop/, "https://artimg2.artart.cn");
                  idcard.value = res.data.idCard_num;
                  real_name.value = res.data.name;
                  frontData.value = {
                    birth_date: res.data.birth_date,
                    nation: res.data.nation,
                    sex: res.data.sex,
                    address: res.data.address,
                  };
                } else {
                  idcard_back_photo.value = result.data.data.image_url.replace(/^https:\/\/cdn.weipai.shop/, "https://artimg2.artart.cn");
                  backData.value = {
                    expiration_date: res.data.ExpirationDate,
                    push_police: res.data.PushPolice,
                    push_date: res.data.PushDate,
                  };
                }
              })
              .catch((message) => {
                Toast.clear();
                Toast(message);
              });
          } else {
            Toast.clear();
            if (key === "front") {
              idcard_just_photo.value = result.data.data.image_url.replace(/^https:\/\/cdn.weipai.shop/, "https://artimg2.artart.cn");
            } else {
              idcard_back_photo.value = result.data.data.image_url.replace(/^https:\/\/cdn.weipai.shop/, "https://artimg2.artart.cn");
            }
          }

          // editAction('avatar', result.data.data.image_url);
        } else {
          Toast(result.data.message);
        }
      });
    };

    let saveLock = false;
    const saveAction = () => {
      if (!checked.value) {
        Toast("请阅读完并同意隐私协议后操作");
        return false;
      }
      // 提交认证
      if (
        !idcard_just_photo.value ||
        !idcard_back_photo.value ||
        !idcard.value ||
        !real_name.value
      ) {
        Toast("信息补全，请补全信息");
        return false;
      }
      if (saveLock) return false;
      saveLock = true;
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      applyVerify({
        real_name: real_name.value,
        idcard: idcard.value,
        idcard_type: idcard_type.value,
        idcard_just_photo: idcard_just_photo.value,
        idcard_back_photo: idcard_back_photo.value,
        ...frontData.value,
        ...backData.value,
      })
        .then(() => {
          Toast.clear();
          Toast("操作成功");
          saveLock = false;

          const isWx = isWeiXin();
          if (isWx) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.getEnv((res) => {
              if (res.miniprogram) {
                // eslint-disable-next-line no-undef
                wx.miniProgram.navigateBack();
              } else {
                router.back();
              }
            });
          } else {
            router.back();
          }
        })
        .catch((message) => {
          Toast.clear();
          Toast(message);
          saveLock = false;
        });
    };

    const goPageAction = (link) => {
      router.push(link);
    };

    return {
      real_name,
      idcard,
      idcard_just_photo,
      idcard_back_photo,
      idcard_type,
      columns,
      showChangeType,
      constoryIndex,
      rightBtnStyle,
      checked,
      verify_status,

      showChangeTypeAction,
      onConfirm,
      kong,
      onCancel,
      modeUpload,
      saveAction,
      goPageAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f7f7f7;
  .edit {
    background: #fff;
    .edit-avatar {
      padding-top: 64px;
    }
    .edit-item {
      padding: 0 32px;
      min-height: 120px;
      box-shadow: 0px 2px 0px 0px #eeeeee;
      font-size: 28px;
      .l {
        width: 192px;
        color: #666;
      }
      .c {
        flex: 1;
        color: #000;
        font-weight: 500;

        input {
          border: none;
          background: none;
          line-height: 60px;
          width: 100%;
        }
      }
      .r {
        img {
          width: 32px;
          height: 32px;
          display: block;
        }
      }
    }

    .id-img {
      width: 240px;
      height: 160px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 2px solid #eeeeee;

      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }

    .upload {
      width: 240px;
      height: 160px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 8px;
      border: 2px solid #eeeeee;

      img {
        width: 48px;
        height: 48px;
      }

      .txt {
        font-size: 24px;
        color: #999;
        font-weight: 400;
      }
    }

    .mt32 {
      margin-top: 32px;
    }

    .pb32 {
      padding-bottom: 48px;
    }
  }
}
.prop {
  color: #999;
  font-size: 24px;
  margin-top: 32px;

  img {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 8px;
  }
}

.show-change {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2003;
}
.show-change-bg {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2002;
}

.save-btn {
  position: fixed;
  bottom: 70px;
  left: 24px;
  right: 24px;
  height: 96px;
  text-align: center;
  line-height: 96px;
}
</style>